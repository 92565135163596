import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getMessaging } from "firebase/messaging";
import { getFunctions, httpsCallable } from "firebase/functions";
const firebaseConfig ={
  // apiKey: "AIzaSyCO5ZEh8M2sGrZPq9ZTm5T2bxSksml9NQY",
  // authDomain: "fidus-finance.firebaseapp.com",
  // databaseURL: "https://fidus-finance-default-rtdb.europe-west1.firebasedatabase.app",
  // projectId: "fidus-finance",
  // storageBucket: "fidus-finance.appspot.com",
  // messagingSenderId: "528260690137",
  // appId: "1:528260690137:web:9eb7e67e9f51b78116cec8",
  // measurementId: "G-WNEZPLZWL6"

  apiKey: "AIzaSyByOsizt1FM43RQ8nwek_MaXEPYeYhdx4c",
  authDomain: "fiddux-5a1f1.firebaseapp.com",
  projectId: "fiddux-5a1f1",
  storageBucket: "fiddux-5a1f1.appspot.com",
  messagingSenderId: "1074068484002",
  appId: "1:1074068484002:web:b920d5f24ba4e4be61ccfb",
  measurementId: "G-V3889JW65S"
}
const app = initializeApp(firebaseConfig);
export const AUTH_LOGIN = getAuth(app);
export const DATABASE = getFirestore(app);
export const STORAGE = getStorage(app);
export const MESSAGING = getMessaging(app);
export const FUNCTIONS = getFunctions(app);