import {
  Box,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import TableContainer from "../../../../Components/TableContainer/TableContainer";
import { useNavigate } from "react-router-dom";
import {
  collection,
  getDocs,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";
import { DATABASE } from "../../../../Config/firebase";
import useDebounce from "../../../../Components/useDebounce/useDebounce";
import moment from "moment";
import BarChartIcon from "@mui/icons-material/BarChart";
import MonthlyCandelChartModel from "./MonthlyCendelChartModel";
import CustomSearch from "../../../../Components/CustomSearch/CustomSearch";
import dayjs from "dayjs";
import YearPicker from "../../../../Components/YearPicker/YearPicker";
import { dolorFormatAmount } from "../../../../Utils/Services";
const MonthlyGraph = () => {
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(50);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [monthlyGraphList, setMonthlyGraphList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [performanceShowModel, setPerformanceShowModel] = useState(false);
  const [performanceGraphDetails, setPerformanceGraphDetails] = useState({});
  const [searchCustmer, setSearchCustmer] = useState("");
  const debouncedSearch = useDebounce(searchCustmer, 500);
  const [seletedMonthYear, setSelectedMonthYear] = React.useState(null);
  const columns = [
    {
      field: "rowid",
      headerName: "Sr.No.",
      minWidth: 100,
      hideOnMobile: true,
      renderCell: (params) => {
        return (
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "400",
            }}
          >
            {params?.row?.rowid}
          </Typography>
        );
      },
    },
    {
      field: "user_name",
      headerName: "Name",
      minWidth: 200,
      renderCell: (params) => {
        return (
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "400",
            }}
          >
            {params?.row?.user_name}
          </Typography>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 250,
      hideOnMobile: true,
      renderCell: (params) => {
        return (
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "400",
            }}
          >
            {params?.row?.email}
          </Typography>
        );
      },
    },
    {
      field: "start_date",
      headerName: "Month Date",
      minWidth: 250,
      flex: 1,
      renderCell: (params) => {
        const { start_date } = params?.row;
        const seconds = start_date?.seconds;
        const nanoseconds = start_date?.nanoseconds;
        const milliseconds = seconds * 1000 + Math.floor(nanoseconds / 1000000);
        const date = moment(milliseconds);
        return <div>{date.format("MMMM Do YYYY, h:mm:ss A")}</div>;
      },
    },
    {
      field: "gross_performance_percentage",
      headerName: "Performance",
      minWidth: 120,
      renderCell: (params) => {
        const data = `${params?.row?.gross_performance_percentage?.toFixed(2) || 0}%`;
        return data;
      },      
    },
    {
      field: "fiddux_commission",
      headerName: "Fiddux Commission",
      minWidth: 200,
      hideOnMobile: true,
      renderCell: (params) => {
        const data = params?.row?.fiddux_commission;
        return <>{data ? "$" + dolorFormatAmount(data) : "-"}</>;
      },      
    },
    {
      field: "total_Monthly_percantage",
      headerName: "Total Monthly Percentage",
      minWidth: 200,
      hideOnMobile: true,
      renderCell: (params) => {
        const data = `${params?.row?.total_Monthly_percantage?.toFixed(2) || 0}%`;
        return data;
      }, 
    },
    {
      field: "profit_to_user",
      headerName: "Profit To User",
      minWidth: 200,
      hideOnMobile: true,
      renderCell: (params) => {
        const data = params?.row?.profit_to_user;
        return <>{data ? "$" + dolorFormatAmount(data) : "-"}</>;
      },
      
    },
    {
      field: "graph_points",
      headerName: "Graph",
      minWidth: 20,
      renderCell: (params) => {
        return (
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "400",
            }}
            onClick={() => handleShowHideMonthlyGraph(params?.row)}
          >
            <BarChartIcon />
          </Typography>
        );
      },
    },
  ];
  useEffect(() => {
    getMonthlyList(page, pageSize, debouncedSearch);
  }, [page, debouncedSearch, seletedMonthYear]);
  const getMonthlyList = async (
    currentPage = 0,
    pageSize = 50,
    debouncedSearch = ""
  ) => {
    setIsLoading(true);

    let q = query(
      collection(DATABASE, "monthly_grpahs"),
      orderBy("graph_month_date", "desc")
    );

    const unsubscribe = onSnapshot(
      q,
      async (querySnapshot) => {
        const transactions = querySnapshot?.docs?.map((doc, index) => ({
          rowid: index + 1,
          id: doc?.id,
          ...doc?.data(),
        }));

        const usersSnapshot = await getDocs(collection(DATABASE, "users"));
        const usersList = usersSnapshot?.docs?.map((userDoc) => ({
          doc_id: userDoc?.id,
          email: userDoc?.data()?.email,
          registered_date: userDoc?.data()?.registered_date,
          name: userDoc?.data()?.full_name,
          ...userDoc?.data(),
        }));

        const monthlyWithUserNames = transactions?.map((elem) => {
          const matchedUser = usersList.find(
            (cust) => cust?.doc_id === elem?.user_id
          );
          return {
            ...elem,
            user_name: matchedUser?.full_name || "Unknown",
            email: matchedUser?.email || "--",
            registered_date: matchedUser?.registered_date || "--",
          };
        });
        const filteredMonthly = debouncedSearch
          ? monthlyWithUserNames?.filter(
            (elem) =>
              elem?.email
                .toLowerCase()
                .includes(debouncedSearch.toLowerCase()) ||
              elem?.user_name
                .toLowerCase()
                .includes(debouncedSearch.toLowerCase())
          )
          : monthlyWithUserNames;
        const filteredByMonthYear = seletedMonthYear
          ? filteredMonthly?.filter((elem) => {
            const date = moment(
              elem?.graph_month_date?.seconds * 1000 +
              Math.floor(elem?.graph_month_date?.nanoseconds / 1000000)
            );
            return (
              date.month() === dayjs(seletedMonthYear).month() &&
              date.year() === dayjs(seletedMonthYear).year()
            );
          })
          : filteredMonthly;

        const paginatedMonthly = filteredByMonthYear?.slice(
          currentPage * pageSize,
          (currentPage + 1) * pageSize
        );

        setMonthlyGraphList(paginatedMonthly);
        setTotalCount(filteredByMonthYear?.length);
        setIsLoading(false);
      },
      (error) => {
        console.error("Error fetching deposits:", error);
        setIsLoading(false);
      }
    );
    return unsubscribe;
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPage(0);
    getMonthlyList(0, newPageSize, debouncedSearch);
  };
  const handlePageChange = (newPage) => {
    setPage(newPage);
    getMonthlyList(newPage, pageSize, debouncedSearch);
  };
  const handleShowHideMonthlyGraph = (graphPoints) => {
    setPerformanceShowModel(true);
    setPerformanceGraphDetails(graphPoints);
  };
  return (
    <>
      <Box
        sx={{
          width: "100%",
          paddingTop:{xs:'60px', sm:"0px"}
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
              Monthly Graph Details
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Button
                onClick={() => navigate("/admin/add-monthly-graph")}
                sx={{
                  background:
                    "linear-gradient(148.97deg, #0C2C50 0%, #450F5B 100%)",
                  color: "#fff",
                  height: "46px",
                  padding: "0px 20px",
                  textTransform: "capitalize",
                  "&:hover": {
                    background:
                      "linear-gradient(148.97deg, #0C2C50 0%, #450F5B 100%)",
                    color: "#fff",
                  },
                }}
              >
                + Create Monthly Graph
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} md={12}>
            <Grid container spacing={3}  alignItems={'center'}>
              <Grid item xs={12} md={6}>
                <CustomSearch
                  placeholder="Search Customer (Email, Full Name)"
                  value={searchCustmer}
                  onChange={(event) => setSearchCustmer(event.target.value)}
                  onClear={() => setSearchCustmer("")}
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ width: '300px', display: 'flex', justifyContent: 'flex-end' }}>
                <Box sx={{
                  width: '300px'
                }}>
                  <YearPicker
                    value={seletedMonthYear}
                    onChange={(newValue) => setSelectedMonthYear(newValue)}
                    placeholder="Select Month"
                    InputProps={{
                      sx: {
                        height: 40,
                        border: "none",
                        "& .MuiFormControl-root": { border: "none" },
                      },
                    }}
                    name="select_Month"
                  />
                </Box>

              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <TableContainer
              rows={monthlyGraphList}
              columns={columns}
              loading={isLoading}
              page={page}
              pageSize={pageSize}
              totalCount={totalCount}
              handlePageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
              paginationShow={true}
            />
          </Grid>
        </Grid>
      </Box>
      {performanceShowModel && (
        <MonthlyCandelChartModel
          open={performanceShowModel}
          onClose={() => setPerformanceShowModel(false)}
          handleOnClose={() => setPerformanceShowModel(false)}
          performanceGraphDetails={performanceGraphDetails}
        />
      )}
    </>
  );
};

export default MonthlyGraph;
