import { Box, Checkbox, Grid, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomSearch from "../../../../Components/CustomSearch/CustomSearch";
import TableContainer from "../../../../Components/TableContainer/TableContainer";
import { collection, orderBy, query, onSnapshot, where } from "firebase/firestore";
import { DATABASE } from "../../../../Config/firebase";
import useDebounce from "../../../../Components/useDebounce/useDebounce";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
const CustomerSelection = ({
  multipleUsersEntry,
  setMultipleUsersEntryList,
  select_user,
  setFieldValue,
  setIsNotify,
  isNotify
}) => {
  const [pageSize, setPageSize] = useState(50);
  const [customerList, setCustomerList] = useState([]);
  const [allUser, setAllUser] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchCustomer, setSearchCustomer] = useState("");
  const debouncedSearch = useDebounce(searchCustomer, 500);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  useEffect(() => {
    if (allUser?.length > 0) {
      const allCustomerIds = allUser?.map((customer) => customer.id);
      setFieldValue("select_user", allCustomerIds);
    }
  }, [allUser?.[0]?.id]);

  const handleCommissionPercentageChange = (e, userId) => {
    const inputValue = parseFloat(e.target.value) || 0;

    setMultipleUsersEntryList((prevList) => {
      return prevList?.map((entry) => {
        if (entry?.user_id?.id === userId) {
          const updatedCommissionPercentage = inputValue;

          const updatedTotalProfit =
            (entry?.amount_before_performance *
              entry?.gross_performance_percentage) /
            100;

          const updatedCommission =
            (updatedTotalProfit * updatedCommissionPercentage) / 100;

          const profitAfterCommission = updatedTotalProfit - updatedCommission;
          const profitToUser = updatedTotalProfit - updatedCommission;

          return {
            ...entry,
            fiddux_commission_percentage: updatedCommissionPercentage,
            balance_after_commission:
              parseFloat(entry?.amount_before_performance) +
              parseFloat(updatedTotalProfit) -
              updatedCommission,
            fiddux_commission: updatedCommission,
            total_Monthly_Profit_Loss: updatedTotalProfit,
            gross_performance_balance:
              parseFloat(entry?.amount_before_performance) +
              parseFloat(updatedTotalProfit),
            percentage_after_commission:
              (profitAfterCommission * 100) / entry?.amount_before_performance,
            profit_to_user: profitToUser,
          };
        }
        return entry;
      });
    });
  };
  const handleSelectUser = (userId) => {
    let updatedSelection;
    if (select_user?.includes(userId)) {
      updatedSelection = select_user?.filter((id) => id !== userId);
    } else {
      updatedSelection = [...select_user, userId];
    }
    setFieldValue("select_user", updatedSelection);
  };
  const handlePerformanceChange = (e, userId) => {
    const inputValue = e.target.value;
    const newValue = inputValue === "" ? "" : parseFloat(inputValue);
    setMultipleUsersEntryList((prevList) => {
      return prevList?.map((entry) => {
        if (entry?.user_id?.id === userId) {
          const amountBeforePerformance =
            parseFloat(entry?.amount_before_performance) || 0;
          const updatedTotalProfit = (amountBeforePerformance * newValue) / 100;
          const updatedCommision = newValue
            ? (updatedTotalProfit *
              (entry?.fiddux_commission_percentage || 0)) /
            100
            : 0;

          const profitAfterCommission = updatedTotalProfit - updatedCommision;
          const profitToUser = profitAfterCommission;
          const updatedGrossPerformanceBalance =
            amountBeforePerformance + updatedTotalProfit;

          return {
            ...entry,
            gross_performance_percentage: newValue,
            total_Monthly_Profit_Loss: updatedTotalProfit,
            gross_performance_balance: updatedGrossPerformanceBalance || 0,
            fiddux_commission: updatedCommision,
            percentage_after_commission:
              amountBeforePerformance > 0
                ? (profitAfterCommission * 100) / amountBeforePerformance
                : 0,
            profit_to_user: profitToUser,
            balance_after_commission:
              updatedGrossPerformanceBalance - updatedCommision,
          };
        }
        return entry;
      });
    });
  };
  const handleSelectAllToggle = () => {
    if (select_user?.length) {
      setFieldValue("select_user", []);
      return;
    }
    const allCustomerIds = allUser?.map((customer) => customer?.id);
    setFieldValue("select_user", allCustomerIds);
  };
  const handleNotificationChange = (userId) => {
    setIsNotify((prevNotifyList) => {
      if (prevNotifyList?.includes(userId)) {
        return prevNotifyList?.filter((id) => id !== userId);
      } else {
        return [...prevNotifyList, userId];
      }
    });
  };
  const handleSelectAllNotificationToggle = () => {
    if (isNotify?.length === allUser?.length) {
      setIsNotify([]);
    } else {
      const allCustomerIds = allUser?.map((customer) => customer?.id);
      setIsNotify(allCustomerIds);
    }
  };
  const columns = [
    {
      field: "rowid",
      filterable: false,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      headerName: (
        <Checkbox
          checked={select_user?.length === allUser?.length}
          indeterminate={
            select_user?.length > 0 && select_user?.length < customerList?.length
          }
          onChange={handleSelectAllToggle}
        />
      ),
      minWidth: 110,
      renderCell: (params) => (
        <Checkbox
          checked={select_user?.includes(params?.row?.id)}
          onChange={() => handleSelectUser(params?.row?.id)}
        />
      ),
    },
    {
      field: "notification",
      filterable: false,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      headerName: (
        <Box sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer"
        }}>
          Notfiy
          <Checkbox
            checked={isNotify?.length === allUser?.length}
            indeterminate={
              isNotify?.length > 0 && isNotify?.length < allUser?.length
            }
            onChange={handleSelectAllNotificationToggle}
          />
        </Box>
      ),
      minWidth: 120,
      renderCell: (params) => (
        <Checkbox
          checked={isNotify?.includes(params?.row?.id)}
          onChange={() => handleNotificationChange(params?.row?.id)}
        />
      ),
    },
    {
      field: "profile_image",
      headerName: "Profile",
      minWidth: 50,
      filterable: false,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) =>
        params?.row?.profile_image ? (
          <img
            src={params?.row?.profile_image}
            alt="profile"
            style={{ width: "40px", height: "40px", borderRadius: "10px" }}
          />
        ) : (
          <AccountCircleIcon sx={{ fontSize: "40px" }} />
        ),
    },
    {
      field: "full_name",
      headerName: "Full Name",
      minWidth: 130,

      renderCell: (params) => {
        const fullName = params?.row?.full_name || "--";
        return (
          <Tooltip title={fullName?.length > 25 ? fullName : ""}>
            <span>
              {fullName?.length > 25
                ? `${fullName.substring(0, 25)}...`
                : fullName}
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 200,
      renderCell: (params) => (
        <Tooltip title={params?.row?.email || "--"}>
          <span>{params?.row?.email || "--"}</span>
        </Tooltip>
      ),
    },
    {
      field: "phone",
      headerName: "Phone Number",
      minWidth: 160,
      renderCell: (params) => {
        const phone = params?.row?.phone?.split(",") || [];
        return (
          <Tooltip title={phone[1] ? `${phone[1]} - ${phone[2]}` : "--"}>
            <span>{phone[1] ? `${phone[1]} - ${phone[2]}` : "--"}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "amount_before_performance",
      headerName: "Net Balance",
      minWidth: 200,
      renderCell: (params) => {
        const formattedBalance = new Intl.NumberFormat("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(params.row.net_balance);
        return formattedBalance
      },
    },
    {
      field: "gross_performance_percentage",
      headerName: "Performance(%)",
      minWidth: 120,
      renderCell: (params) => {
        const user = multipleUsersEntry?.find(
          (e) => e?.user_id?.id === params?.row?.doc_id
        );
        const value = user?.gross_performance_percentage?.toString() || "";

        return (
          <input
            type="number"
            value={value}
            onChange={(e) => handlePerformanceChange(e, params?.row?.doc_id)}
            style={{ width: "100%", padding: "5px" }}
          />
        );
      },
    },
    {
      field: "fiddux_commission_percentage",
      headerName: "Fiddux Commission Percentage",
      minWidth: 120,
      renderCell: (params) => {
        const user = multipleUsersEntry?.find(
          (e) => e?.user_id?.id === params?.row?.doc_id
        );
        const value = user?.fiddux_commission_percentage?.toString() || "";

        return user ? (
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            {" "}
            <input
              type="number"
              value={value}
              onChange={(e) =>
                handleCommissionPercentageChange(e, params?.row?.doc_id)
              }
              style={{ width: "100%", padding: "5px" }}
            />
            <span>%</span>
          </Box>
        ) : (
          "0%"
        );
      },
    },
    {
      field: "fiddux_commission",
      headerName: "Fiddux Commission",
      minWidth: 120,
      renderCell: (params) => {
        const user = multipleUsersEntry?.find(
          (e) => e?.user_id?.id === params?.row?.doc_id
        );
        if (user && user?.total_Monthly_Profit_Loss && user?.fiddux_commission_percentage) {
          const commission = (user?.total_Monthly_Profit_Loss * user?.fiddux_commission_percentage) / 100;
          const formattedCommission = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(commission);

          return <span>{formattedCommission}</span>;
        }
        return <span>$0</span>;
      },
    },
    {
      field: "gross_performance_percentage1",
      headerName: "Peformance",
      minWidth: 120,
      renderCell: (params) => {
        const user = multipleUsersEntry?.find(
          (e) => e?.user_id?.id == params?.row?.doc_id
        );

        return user ? (
          <span>
            {`${user?.gross_performance_percentage?.toFixed(4)}%` || "0%"}
          </span>
        ) : (
          "0%"
        );
      },
    },
    {
      field: "percentage_after_commission",
      headerName: "Liquid Performance(%)",
      minWidth: 120,
      renderCell: (params) => {
        const user = multipleUsersEntry?.find(
          (e) => e?.user_id?.id == params?.row?.doc_id
        );

        return user ? (
          <span>
            {`${user?.percentage_after_commission?.toFixed(4)}%` || "0%"}
          </span>
        ) : (
          "0%"
        );
      },
    },
    {
      field: "balance_after_commission",
      headerName: "Liquid Performance",
      minWidth: 200,
      renderCell: (params) => {
        const user = multipleUsersEntry?.find(
          (e) => e?.user_id?.id == params?.row?.doc_id
        );
        const formattedCommission = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(user?.balance_after_commission);
        return formattedCommission
      },
    },
    {
      field: "profit_to_user",
      headerName: "Profit to Client",
      minWidth: 200,
      renderCell: (params) => {
        const user = multipleUsersEntry?.find(
          (e) => e?.user_id?.id == params?.row?.doc_id
        );
        const formattedCommission = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(user?.profit_to_user);
        return formattedCommission
      },
    },
  ];

  const fetchCustomerList = async (currentPage, pageSize, debouncedSearch) => {
    setIsLoading(true);
    const customerQuery = query(
      collection(DATABASE, "users"),
      orderBy("registered_date", "desc"),
      where("status", "==", 1)
    );
    onSnapshot(
      customerQuery,
      (snapshot) => {
        const data = snapshot?.docs
          .map((doc, index) => ({
            rowid: index + 1,
            id: doc?.id,
            ...doc?.data(),
          }))?.filter((doc) => doc?.role === 1);
        setAllUser(data);
        const searchFilter = debouncedSearch?.toLowerCase();
        const filteredData = data?.filter(
          ({ email = "", full_name = "", net_balance = "" }) =>
            email?.toLowerCase()?.includes(searchFilter) ||
            full_name?.toLowerCase()?.includes(searchFilter) ||
            net_balance?.toString()?.includes(searchFilter)
        );
        setCustomerList(
          filteredData?.slice(
            currentPage * pageSize,
            (currentPage + 1) * pageSize
          )
        );
        setTotalCount(filteredData?.length);
        setIsLoading(false);
      },
      (error) => {
        console.error("Error fetching customers:", error);
        setIsLoading(false);
      }
    );
  };

  useEffect(() => {
    fetchCustomerList(page, pageSize, debouncedSearch);
  }, [debouncedSearch, page, pageSize]);

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <CustomSearch
            placeholder="Search Customer (Email, Full Name, Ledger)"
            value={searchCustomer}
            onChange={(event) => setSearchCustomer(event.target.value)}
            onClear={() => setSearchCustomer("")}
            style={{ border: "1px solid #000" }}
          />
        </Grid>
      </Grid>
      <Box sx={{ width: "100%", paddingTop: "24px" }}>
        <TableContainer
          rows={customerList}
          columns={columns}
          loading={isLoading}
          page={page}
          pageSize={pageSize}
          totalCount={totalCount}
          handlePageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          paginationShow={true}
          SXHEAD={{
            background: "linear-gradient(148.97deg, #0C2C50 0%, #450F5B 100%)",
            color: "#fff",
          }}
        />
      </Box>
    </Box>
  );
};
export default CustomerSelection;
